var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-tr",
    [
      _c("vs-td", { key: "position" }, [
        _c(
          "div",
          { staticClass: "mx-auto" },
          [
            _c("feather-icon", {
              attrs: {
                svgClasses: "w-4 h-4",
                type: "filled",
                icon: "MoreVerticalIcon",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-td", { key: "name" }, [
        _c(
          "div",
          { staticClass: "mx-auto" },
          [
            _c("feather-icon", {
              attrs: {
                svgClasses: "w-4 h-4",
                type: "filled",
                icon: "MoreVerticalIcon",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-td", { key: "score" }, [
        _c(
          "div",
          { staticClass: "mx-auto" },
          [
            _c("feather-icon", {
              attrs: {
                svgClasses: "w-4 h-4",
                type: "filled",
                icon: "MoreVerticalIcon",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }